import { action } from 'typesafe-actions'
import { RecorrenciaTypes, Recorrencia } from './types'

export const setFilterListRequest = () => action(RecorrenciaTypes.SET_FILTER_LIST_REQUEST)
export const setFilterListSuccess = () => action(RecorrenciaTypes.SET_FILTER_LIST_SUCCESS)
export const setFilterListFailure = () => action(RecorrenciaTypes.SET_FILTER_LIST_FAILURE)

export const getListRequest = (filters: {
  cota?: string
  grupo?: string
  cpfCnpj?: string
  ativo?: number
  page?: number
  limit?: number
}) => action(RecorrenciaTypes.GET_LIST_REQUEST, { filters })

export const getListSuccess = (data: Recorrencia[]) => action(RecorrenciaTypes.GET_LIST_SUCCESS, { data })

export const getListFailure = () => action(RecorrenciaTypes.GET_LIST_FAILURE)
