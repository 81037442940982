export enum RecorrenciaTypes {
  GET_LIST_REQUEST = '@@recorrencia/GET_LIST_REQUEST',
  GET_LIST_SUCCESS = '@@recorrencia/GET_LIST_SUCCESS',
  GET_LIST_FAILURE = '@@recorrencia/GET_LIST_FAILURE',
  SET_FILTER_LIST_REQUEST = '@@recorrencia/SET_FILTER_LIST_REQUEST',
  SET_FILTER_LIST_SUCCESS = '@@recorrencia/SET_FILTER_LIST_SUCCESS',
  SET_FILTER_LIST_FAILURE = '@@recorrencia/SET_FILTER_LIST_FAILURE',
}

export interface Recorrencia {
  idTransacao: number
  idDocumento: number
  idCota: number
  cdCota: string
  cdGrupo: string
  cpfCnpj: string
  nome: string
  newconIdPessoa: number
  dataVencimento: string
  dataDebito: string
  dateInMls: number
  paymentType: string
  ativo: boolean
  tipoRecorrencia: string
  dataProxCobranca: string
  dataUltimaCobranca: string
  dataCriacao: string
  dataAlteracao: string
  dataCancelamento: string
  qtdTentativasCobranca: number
  logsPagamento: string
}

export interface IRecorrenciaState {
  data: any[]
  loading: boolean
  error: boolean
  totalItems: number
  currentPage: number
  totalPages: number
}
